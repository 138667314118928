$width: 500px;
$maxWidth: 90vw;
$height: 90vh;
.ai-float-chatbox {
  //   border: 1px solid red;
  position: fixed;
  right: 32px;
  bottom: 16px;
  width: $width;
  max-width: $maxWidth;

  z-index: 1000;

  .chatbox-container {
    position: relative;
    border-radius: 10px;
    background-color: whitesmoke;
    box-shadow: 0 0 10px 0px #777;
    width: 100%;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    overflow: hidden;

    &.hidden {
      width: 0;
      height: 0;
    }
    .chat-title {
      background: linear-gradient(to bottom, #333 0%, #555 100%);
      width: $width;
      max-width: $maxWidth;
      svg {
        color: white;
      }
    }
    .chatbox-menu {
    }
    .chatbox-chat-side {
      height: $height;
    }
    .chatbox-session-side {
      svg {
        color: white;
      }
      height: $height;
      background: linear-gradient(to bottom, #333 0%, #555 100%);
      color: azure;
      width: 300px;
    }
  }
  .ai-session-list-search-box {
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;

    input {
      color: white;
    }
  }
  .sessions-list {
    color: whitesmoke;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: clip;
    scrollbar-width: thin;
    scrollbar-color: rgba(#000, 0.3) transparent;
    .current::before {
      content: ' ';
      margin-left: -24px;
      display: inline-block;
      position: absolute;
      width: 12px;
      height: 80%;
      border-left: 1px solid gray;
    }
  }
}
