$color-guide-label: #7fc15e;
$color-guide-label-text: #d0f1c0;
$color-time-pointer: #ff6969;
$color-recycle-row: rgba(red, 0.1);
$color-recycle-row-highlight: rgba(red, 0.2);
$color-row-highlight: rgba($color-guide-label, 0.2);
$color-item-highlight: rgb(0, 0, 0);
$color-row-spacer: rgba(#080, 0.5);
$height-time-band: 50px;
$height-row: 36px;

.dnd-timeline {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  * {
    // transition: all 0.2s ease;
    box-sizing: border-box;
  }

  .dndt-addition-area {
    display: inline-block;
  }

  .dndt-times {
    .dndt-time-band {
      display: inline-block;
      white-space: nowrap;
      padding-top: 8px;

      .dndt-time-guide {
        display: inline-block;

        &:last-child {
          width: 0 !important;
        }

        .dndt-time-guide-date {
          display: block;
          width: 100%;
          font-size: smaller;
          color: $color-guide-label;
          min-height: 16px;
          line-height: 16px;
        }

        .dndt-time-guide-hour {
          .label {
            display: inline-block;
            background-color: $color-guide-label;
            padding: 0 4px 0 4px;
            border-radius: 5px 5px 5px 0;
            color: $color-guide-label-text;
            position: relative;
            z-index: 9;

            span.hour {
              font-weight: bold;
            }

            span.minutes {
              font-size: smaller;
            }
          }
        }

        .dndt-time-guide-minutes {
          font-size: small;
          color: gray;
        }
      }
    }

    .dndt-border-guides {
      .dndt-time-guide {
        display: inline-block;
        position: absolute;
        top: $height-time-band;
        bottom: 0;
        width: 2px;
        border-right: 1.5px dashed $color-guide-label;

        &.minor {
          border-right: 1px dashed lighten($color-guide-label, 25);
        }

        &.major {
          z-index: 5;
        }
      }
    }

    .dndt-time-pointer {
      position: absolute;
      border-radius: 2.5px;
      top: $height-time-band;
      bottom: 0px;
      width: 3px;
      background-color: rgba($color-time-pointer, 0.5);
      z-index: 11;
      .current-time {
        display: inline-block;
        background-color: $color-time-pointer;
        padding: 0 4px 0 4px;
        border-radius: 5px;
        color: lighten($color-time-pointer, 100);
        position: absolute;
        text-align: center;
        width: 40px;
        line-height: 20px;
        margin: -26px 0px 0px -20px;
      }
      &::before {
        display: inline-block;
        position: absolute;
        height: 8px;
        width: 15px;
        margin-left: -6px;
        margin-top: -7px;
        text-align: center;
        line-height: 8px;
        top: 0;
        left: 0;
        content: '\25BC';
        color: $color-time-pointer;
      }
      &::after {
        display: inline-block;
        position: absolute;
        height: 8px;
        width: 15px;
        margin-left: -6px;
        margin-bottom: -7px;
        text-align: center;
        line-height: 8px;
        bottom: 0;
        left: 0;
        content: '\25B2';
        color: $color-time-pointer;
      }
      &.not-in-range {
        display: none;
      }
    }
  }
  .dnd-chart {
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    max-height: 30vh;
  }
  .dnd-footer {
    display: flex;
    z-index: 12;
    .dndt-row {
      margin-top: 0;
    }
  }
}

.dndt-row {
  display: flex;
  position: relative;
  height: $height-row;
  align-items: center;
  white-space: nowrap;
  min-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 8px;
  &.non-editable {
    opacity: 0.5;
    .dndt-row-not-editable {
      position: absolute;
      height: 80%;
      width: 0;
      padding: 2px 5px 2px 24px;
      top: 10%;
      left: 4px;
      background-color: white;
      border: 1px solid red;
      z-index: 11;
      box-shadow: #000 2px 2px;
      &::before {
        display: inline-block;
        position: absolute;
        content: '\26A0';
        color: red;
        margin-left: -16px;
      }
    }
  }
  &.highlight {
    background-color: $color-row-highlight;
  }
  .dndt-chart-area {
    display: inline-flex;
    height: $height-row;
    align-items: center;
    white-space: nowrap;
    // min-width: 100%;
    // width: fit-content;
    .dndt-row-end-area {
      display: inline-block;
    }
  }
  .dndt-row-item {
    display: inline-flex;
    align-items: center;
    text-align: center;
    border: 1px dotted gray;
    border-radius: 5px;
    height: 80%;
    background-color: beige;
    z-index: 10;
    overflow: hidden;

    &.highlight {
      box-shadow: $color-item-highlight 0 0 10px;
      margin-top: -10px;
      background-color: black !important;
      color: white;
    }
    &.hidden {
      display: none;
      & + .dndt-recycle-row-spacer {
        display: none;
      }
    }

    &.color-unassigned {
      background-color: #a19b9d;
    }

    &.color0 {
      background-color: #e2c682;
    }

    &.color1 {
      background-color: #eba4a4;
    }

    &.color2 {
      background-color: #88d1d1;
    }

    &.color3 {
      background-color: #9bc1f3;
    }

    &.color4 {
      background-color: #b794e4;
    }

    &.color5 {
      background-color: #eeaff0;
    }
  }

  .dndt-row-spacer {
    display: inline-flex;
    height: 2px;
    background-color: $color-row-spacer;

    &.first {
      background-color: transparent;
    }
  }

  .dndt-recycle-row {
    display: flex;
    height: $height-row;
    align-items: center;
    white-space: nowrap;
    background-color: $color-recycle-row;
    opacity: 1;
    flex-grow: 1;

    // &.empty {
    //   position: absolute;
    //   opacity: 0;
    //   top: 0;
    //   left: 0;
    //   &.visible {
    //     opacity: 1;
    //   }
    //   .dndt-recycle-row-spacer {
    //     display: inline-block;
    //     width: 8px;
    //   }
    // }

    &.highlight {
      background-color: $color-recycle-row-highlight;
    }
    .dndt-recycle-row-icon {
      color: red;
    }
  }
  .dndt-recyccle-addition-area {
    background-color: $color-recycle-row;
    display: flex;
    height: $height-row;
    align-items: center;
    justify-content: center;
  }
  .dndt-new-row-button {
    background-color: #080;
  }
  &.dndt-new-row {
    background-color: lighten($color-row-highlight, 20);
    &.highlight {
      background-color: darken($color-row-highlight, 20);
    }
    .dndt-row-icon {
      color: $color-guide-label;
    }
  }
}
