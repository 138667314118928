.filterable-table {
  .row-extend-button {
    svg {
      transition: all 0.5s ease;
      &.is-open {
        transform: rotate(-180deg);
      }
    }
  }
  thead {
    th {
      .sortable-col {
        svg {
          color: #ccc;
        }
        &.is-sort {
          svg {
            color: green;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
      &.extendable-table-row {
        cursor: pointer;
      }
    }
  }
  .extendable-table-row {
    td {
      border-bottom: none;
    }
  }
  .extended-row {
    > td {
      padding: 0;
    }
  }
}
