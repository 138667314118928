@keyframes breathing {
  0%,
  35% {
    filter: drop-shadow(0 0 10px rgb(127, 193, 94));
  }
  50% {
    filter: drop-shadow(0 0 20px rgb(88, 134, 62));
  }
  65%,
  100% {
    filter: drop-shadow(0 0 10px rgb(127, 193, 94));
  }
}

.aiagents-titlebar {
  text-align: center;
  img.logo {
    width: 128px;
    height: 128px;
    filter: drop-shadow(0 0 10px rgb(127, 193, 94));
    animation: breathing 5s linear infinite;
    // transform-origin: 50% 62%;
  }
}
