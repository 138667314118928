$setupAgentModeBGcolor: rgba(192, 244, 253, 0.7);

.prospero-chat-session-header-item {
  margin: 8px 0 0 0 !important;
  cursor: pointer;
  opacity: 0.7;
  border-bottom: 1px dotted #ccc;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    border-bottom: none;
  }

  &.current,
  &:hover.current {
    cursor: default;
    background-color: #eeeeee;
    opacity: 1;
  }

  .prospero-chat-session-header-item-date {
    color: #444;
    text-align: right;
  }
}

.AIdbId {
  display: inline-block;
  code {
    font-size: 90%;
    color: white;
    border: 1px solid;
    margin: 0 8px 0 8px;
    border-radius: 5px;
    padding: 2px 4px 2px 4px;
    background-color: rgb(138, 138, 0);
  }
  span {
    font-size: 90%;
    color: rgb(138, 138, 0);
  }
}

.prospero-message-container {
  .prospero-message-avatar {
    img {
      width: 48px;
      height: 48px;
    }
  }
  &.setup-agent {
    background-color: $setupAgentModeBGcolor;
    padding: 15px;
    .prospero-message-avatar {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.input-setup-agent {
  background-color: darken($setupAgentModeBGcolor, 10);
}

@keyframes animation-bounce {
  0%,
  35%,
  55%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 0.6;
  }
  40% {
    transform: translateY(-5px) scale(1.1, 1.1);
    opacity: 1;
  }
  45% {
    transform: scale(0.9, 0.9) translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-5px) scale(1.1, 1.1);
    opacity: 1;
  }
}

.bouncing {
  animation: animation-bounce 3s infinite;
}

.bouncing-once {
  opacity: 0.6;
  animation: animation-bounce 3s;
  animation-iteration-count: 1;
}

.agent-setup-form {
  background-color: $setupAgentModeBGcolor;
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;

  overflow: hidden;
  max-height: 80vh;
  .agent-setup-form-title {
    color: darken($setupAgentModeBGcolor, 50);
    background-color: $setupAgentModeBGcolor;
  }
  .agent-setup-form-content {
    height: 100%;
    .agent-setup-form-description {
    }
    .agent-setup-form-solution {
      display: flex;
      flex-direction: column;
      min-width: 50%;
    }
    .box {
      position: relative;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 5px;
      padding-left: 36px;
      border: 1px solid darken($setupAgentModeBGcolor, 10);
      border-radius: 10px;
      min-height: 36px;
      .icon {
        color: darken($setupAgentModeBGcolor, 15);
        position: absolute;
        top: 5px;
        left: 5px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
