.ai-page {
  display: flex;
  flex-grow: 1;

  * {
    box-sizing: border-box;
  }

  svg.white {
    color: white;
  }
  .ai-session-sidebar {
    background: linear-gradient(to bottom, #333 0%, #555 100%);
    color: white;
  }
  .ai-session-list-search-box {
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    svg {
      color: white;
    }
    input {
      color: white;
    }
  }
  .sessions-list {
    color: whitesmoke;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: clip;
    scrollbar-width: thin;
    scrollbar-color: rgba(#000, 0.3) transparent;
    .current::before {
      content: ' ';
      margin-left: -24px;
      display: inline-block;
      position: absolute;
      width: 12px;
      height: 80%;
      border-left: 1px solid gray;
    }
  }
}
