@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.1;
  }
  85% {
    opacity: 1;
  }
}
.blink {
  animation: blink-animation 1.2s infinite;
}
