.trip-info-panel-container {
  box-sizing: border-box;

  .trip-info-panel {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    right: 0;
    border-right: 1px solid #7fc15e;
    background-color: #eee;
    .trip-info-panel-title {
      background-color: white;
      border-bottom: 1px solid #ccc;
    }
    .cover {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: transparent;
      z-index: 100;
    }
    .trip-stops-timeline-wrapper {
      position: relative;
      max-height: 100%;
      scrollbar-width: thin;
      .trip-stops-timeline {
        overflow: hidden;
        scrollbar-width: thin;
        position: relative;
        .trip-stops-timeline-item {
          .trip-stops-timeline-item-wrapper {
            border-radius: 5px;
          }
          &.blur {
            opacity: 0.3;
          }
          &:not(.focused) {
            .trip-stops-timeline-item-wrapper {
              background-color: transparent !important;
            }
          }
        }
        .stop-number {
          display: block;
          position: absolute;
          background-color: white;
          color: gray;
          height: 32px;
          width: 32px;
          text-align: center;
          line-height: 32px;
          top: 50%;
          margin-top: -20px;
          left: -24px;
          border-radius: 50%;
          border: 4px solid #eee;
          box-sizing: content-box;
          font-weight: bolder;
          font-size: 18px;
        }
      }
    }
  }
}
